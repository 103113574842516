import getGCS from './gcs';
import getRestaurant from './restaurant';
import getShopping from './shopping';

export default function getSteps(data) {
  
  let steps;

  if (data.agentType === 'feedback') {
    steps = getGCS(data);
  } else if (data.agentType === 'restaurant') {
    steps = getRestaurant(data);
  } else if (data.agentType === 'shopping') {
    steps = getShopping(data);
  } else {
    steps = getGCS(data);
  }

  return steps
}
