import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bubble from './Bubble';
import Image from './Image';
import ImageContainer from './ImageContainer';
import Loading from '../common/Loading';
import TextStepContainer from './TextStepContainer';

import { convertLocal } from "../../../dateFormat";

class TextStep extends Component {
  
  state = {
    loading: true
  };

  componentDidMount() {
    const { step, speak, previousValue, triggerNextStep } = this.props;
    const { component, delay, waitAction } = step;
    const isComponentWatingUser = component && waitAction;

    setTimeout(() => {
      this.setState({ loading: false }, () => {
        if (!isComponentWatingUser && !step.rendered) {
          triggerNextStep();
        }
        speak(step, previousValue);
      });
    }, delay);
  }

  getMessage = () => {
    const { previousValue, step } = this.props;
    const { message } = step;

    return this.urlify(message ? message.replace(/{previousValue}/g, previousValue) : '');
  };

// make the url, phone, email clickable
  urlify = (text) => {
    let html = text;
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
    const phoneRegex = /(\(\d{3}\))[\s-]\d{3}[\s-]\d{4}/g;
  
    if (html.match(urlRegex)) {
      html = html.replace(urlRegex, function (url) {
        return (
          '<a href="' +
          (url.startsWith('www.') ? `http://${url}` : url) +
          '" target="_blank">' +
          url +
          '</a>'
        );
      });
    }
  
      if (html.match(emailRegex)) {
        html = html.replace(emailRegex, function (email) {
          return '<a href="mailto:' + email + '">' + email + '</a>';
        });
      }
  
      if (html.match(phoneRegex)) {
        html = html.replace(phoneRegex, function (phone) {
          return '<a href="tel:' + phone + '">' + phone + '</a>';
        });
      }
  
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };
  
  
  renderMessage = () => {
    const { step, steps, previousStep, triggerNextStep } = this.props;
    const { component } = step;

    if (component) {
      return React.cloneElement(component, {
        step,
        steps,
        previousStep,
        triggerNextStep
      });
    }

    return this.getMessage();
  };

  renderQuoteMessages() {
    const { renderedSteps } = this.props;
    const items = renderedSteps.filter(s => s.id === 'u_order_1');
    const contact = renderedSteps.find(s => s.id === 'u_order_2');

    const textStyle = { color: 'white', padding: 5 };

    return (
      <div style={{ padding: 10, backgroundColor: '#91bad6', marginBottom: 10, borderTopRightRadius: 18, borderTopLeftRadius: 18 }}>
        <div style={textStyle}>Order Items:</div>
        {items.map((i, idx) => { return (<div key={`${idx}`} style={textStyle}>{`- ${i.message}`}</div>); })}
        <div style={textStyle}>Name and Phone:</div>
        <div style={textStyle}>{contact.message}</div>
      </div>
    );
  }

  _editText(userText) {
    const { step, editText } = this.props;
    if (userText) {
      return editText(step);
    }
    return null;
  }

  render() {
    const {
      step,
      isFirst,
      isLast,
      avatarStyle,
      bubbleStyle,
      hideBotAvatar,
      hideUserAvatar
    } = this.props;
    const { loading } = this.state;
    const { avatar, user } = step;

    const showAvatar = user ? !hideUserAvatar : !hideBotAvatar;
    const userText = step.id.startsWith('u_');
    const reviewOrder = step.id === 'f_order_3';

    return (
      <TextStepContainer className={`rsc-ts ${user ? 'rsc-ts-user' : 'rsc-ts-bot'}`} user={user}>
        <ImageContainer className="rsc-ts-image-container" user={user}>
          {isFirst && showAvatar && (
            <Image
              className="rsc-ts-image"
              style={avatarStyle}
              showAvatar={showAvatar}
              user={user}
              src={avatar}
              alt="avatar"
            />
          )}
        </ImageContainer>
        <table style={{ maxWidth: '77%', minWidth: '50%' }} onClick={() => this._editText(userText)}>
          {!loading && isFirst &&
            <tbody>
              <tr>
                <td style={{ fontSize: 10, padding: 2, marginLeft: user ? 0 : 10, marginRight: user ? 15 : 0, marginBottom: 0, float: user ? 'right' : 'left' }}>
                  {convertLocal(step.date)}
                </td>
              </tr>
            </tbody>
          }
          <tbody>
            <tr>
              <td style={{ float: user ? 'right' : 'left', marginBottom: -5 }}>
                <Bubble
                  className="rsc-ts-bubble"
                  style={userText ? { ...bubbleStyle, marginBottom: 5 } : bubbleStyle}
                  user={user}
                  showAvatar={showAvatar}
                  isFirst={isFirst}
                  isLast={isLast}
                >
                  {!loading && reviewOrder && this.renderQuoteMessages()}
                  {loading ? <Loading /> : this.renderMessage()}
                </Bubble>
              </td>
            </tr>
          </tbody>
          {userText && <tbody>
            <tr>
              <td style={{ fontSize: 10, padding: 2, marginLeft: 20, marginBottom: 15, float: user ? 'right' : 'left', fontWeight: 'bold' }}>Tap to Edit</td>
            </tr>
          </tbody>}
        </table>
      </TextStepContainer>
    );
  }
}

TextStep.propTypes = {
  avatarStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  bubbleStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  hideBotAvatar: PropTypes.bool.isRequired,
  hideUserAvatar: PropTypes.bool.isRequired,
  previousStep: PropTypes.objectOf(PropTypes.any),
  previousValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array
  ]),
  speak: PropTypes.func,
  step: PropTypes.objectOf(PropTypes.any).isRequired,
  steps: PropTypes.objectOf(PropTypes.any),
  triggerNextStep: PropTypes.func.isRequired
};

TextStep.defaultProps = {
  previousStep: {},
  previousValue: '',
  speak: () => { },
  steps: {}
};

export default TextStep;
