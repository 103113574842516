import React, { useState } from 'react';
import Carousel from './shared/Carousel';
import Styled from './shared/Styled';
import defaultTheme from './react-simple-chatbot/theme';

const HeaderComponent = Styled(
  function ({ classes, bannerImages, machine }) {
    const [showingMore, setShowingMore] = useState(false);
    return (
      <div style={{ marginBottom: 10 }} className={classes.root}>
        {bannerImages?.length > 0 && <Carousel images={bannerImages} />}
        <div className={`${classes.header} ${showingMore ? classes.expand : ''}`}>
          <div className="download-images">
            <a href="https://google.com">
              <img src={require('./assets/app-store-download.svg')} alt="" />
            </a>
            <a href="https://google.com">
              <img src={require('./assets/play-store-download.svg')} alt="" />
            </a>
            {!showingMore ? <p className="show-more" onClick={() => setShowingMore(true)}>
              Show Details
            </p> : <p className="show-more" onClick={() => setShowingMore(false)}>
              Chat Now
            </p>}
          </div>
          {!showingMore ? <>
            <p className="poweredby-tc-text">
              By using Polypie AI serve, you agree on our&nbsp;
              <div className="links">
                <a href="https://piecode-7292b.web.app/terms-service">
                  Terms
                  </a>
                  &nbsp;and&nbsp;
                  <a href="https://piecode-7292b.web.app/privacy-policy">
                  Privacy
                  </a>
              </div>
            </p>
            <div className="line"></div>
          </> : <>
              <h3 className="title">
                {machine?.businessName}{' '}
              </h3>
              <h5 className="location">
                {machine?.detailLocation}
              </h5>
              <h5 className="name">
                {machine?.title}
              </h5>
              <div className="line"></div>
              {machine?.aboutUs && <>
                <h4 className="about">
                  About
              </h4>
                <p className="about-text">
                  {machine?.aboutUs}
                </p>
              </>}
              <p className="poweredby-text">
                Powered by Polypie AI Service. Copyright 2020, all rights
                reserved.
              </p>
            </>
          }
        </div>
      </div>
    );
  },
  {
    root: {},
    header: {
      color: ({ theme }) => theme.userFontColor,
      padding: '.6em 1.2em',
      '& .line': {
        paddingBottom: 20,
        borderBottom: 'solid 1px #ccc',
      },
      '& .title': {
        padding: '.4em 0 0',
        margin: 0,
      },
      '& .location': {
        padding: '.4em 0 0',
        margin: 0,
      },
      '& .name': {
        padding: '.4em 0 .4em',
        margin: 0,
      },
      '& .show-more': {
        color: ({ theme }) => theme.linkColor,
        fontSize: '1rem',
        fontWeight: 'bold',
        width: '100%',
        margin: 0,
        justifyContent: 'flex-end',
        display: 'flex',
        alignItems: 'center',

        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
        },
      },
      '& .collapse': {
        marginTop: 15,
      },
      '& .download-text': {
        color: ({ theme }) => theme.linkColor,
        margin: '.3em 0 .3em',
        fontWeight: 'bold',
        fontSize: '0.9rem'
      },
      '& .download-images': {
        display: 'flex',
        margin: '5px 0 10px 0',
        '& a': {
          maxWidth: 'calc(33% - 10px)',
          width: '100%',
          '& img': {
            width: 'calc(100% - 10px)',
            maxHeight: 35,
            borderRadius: 30
          },
        },
      },
      '& .poweredby-tc-text': {
        color: ({ theme }) => theme.linkColor,
        margin: '.2em 0 .2em',
        fontSize: '0.8rem',
        '& .links': {
          display: 'inline-block',
          '& a': {
            color: ({ theme }) => theme.linkColor,
            fontSize: '0.8rem',
            fontWeight: 'bold'
          },
        },
      },
      '& .poweredby-text': {
        margin: '.5em 0 .2em',
        textAlign: 'center',
        color: ({ theme }) => theme.linkColor,
        fontSize: '0.8rem',
      },
      '& .about': {
        margin: '.6em 0',
      },
      '& .about-text': {
        marginTop: 0,
        lineHeight: '25px'
      },
    },
    expand: {
      height: 'calc(100vh - 220px)',
      overflowY: 'auto',
      zIndex: 9,
      position: 'absolute',
      background: ({ theme }) => theme.background
    }
  }
);

HeaderComponent.defaultProps = {
  theme: defaultTheme
};

export default HeaderComponent;
