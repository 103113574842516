import styled from 'styled-components';
import defaultTheme from '../../theme';

const OptionElement = styled.button`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.linkColor};
  border-radius: 22px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.linkColor};
  display: inline-block;
  font-size: 14px;
  padding: 10px 12px;

  &:hover {
    background: ${({ theme }) => theme.linkColor};
    color: ${({ theme }) => theme.userFontColor};
  }
  &:active,
  &:hover:focus {
    outline:none;
  }
`;

OptionElement.defaultProps = {
  theme: defaultTheme
};

export default OptionElement;
