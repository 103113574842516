import React from 'react';

import styles from './styles';

function Thanks() {
  return (
    <div style={styles.thankContainer}>
      <div style={styles.emptyScreenContainer}>
        <img
          alt="imageBot"
          src={require('./assets/thanks-bot.gif')}
          style={styles.thankIcon}
        />
      </div>
      <p style={styles.thankText}>
        We have received your messages and our team members will work on it
        shortly. <br />
        Thank you!
      </p>
    </div>
  );
}

export default Thanks;
