import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Option from './Option';
import OptionElement from './OptionElement';
import Options from './Options';
import OptionsStepContainer from './OptionsStepContainer';

class OptionsStep extends Component {
  onOptionClick = ({ value }) => {
    const { triggerNextStep } = this.props;

    triggerNextStep({ value });
  };

  renderOption = option => {
    const { bubbleOptionStyle, step } = this.props;
    const { user, options } = step;
    const { value, label } = option;

    let splBubbleStyle = null;
    if (options.length === 2 && (label === 'Yes' || label === 'No' || label ==='Pickup' || label === 'Delivery')) {
      splBubbleStyle = { display: 'inline-block', marginLeft: 0};
    }

    return (
      <Option key={value} className="rsc-os-option" style={splBubbleStyle}>
        <OptionElement
          className="rsc-os-option-element"
          style={splBubbleStyle ? { ...bubbleOptionStyle, width: 80, marginLeft: 0, textAlign: 'center' } :  bubbleOptionStyle}
          user={user}
          onClick={() => this.onOptionClick({ value })}
        >
          {label}
        </OptionElement>
      </Option>
    );
  };

  render() {
    const { step } = this.props;
    const { options } = step;

    return (
      <OptionsStepContainer className="rsc-os">
        <Options className="rsc-os-options">
          {Object.keys(options).map(key => options[key]).map(this.renderOption)}
        </Options>
      </OptionsStepContainer>
    );
  }
}

OptionsStep.propTypes = {
  bubbleOptionStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  step: PropTypes.objectOf(PropTypes.any).isRequired,
  triggerNextStep: PropTypes.func.isRequired
};

export default OptionsStep;
