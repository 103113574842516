import React from 'react';
import PropTypes from 'prop-types';

const AttachIcon = ({ size }) => (
  <svg
    width={size} 
    height={size}
    viewBox="0 0 1024 1024" 
    version="1.1" 
    xmlns="http://www.w3.org/2000/svg"
    style={{
      paddingTop: 14,
      paddingRight: 16,
      paddingBottom: 12,
      paddingLeft: 16
    }}
  >
    <path d="M768 64c0-35.392 28.608-64 64-64s64 28.608 64 64v608C896 866.432 738.368 1024 544 1024S192 866.432 192 672V256C192 114.624 306.624 0 448 0s256 114.624 256 256v416c0 88.384-71.616 160-160 160S384 760.384 384 672V256a64 64 0 1 1 128 0v416a32 32 0 0 0 64 0V256c0-70.656-57.344-128-128-128s-128 57.344-128 128v416C320 795.712 420.288 896 544 896S768 795.712 768 672V64z" fill="" />
  </svg>
);

AttachIcon.propTypes = {
  size: PropTypes.number
};

AttachIcon.defaultProps = {
  size: 20
};

export default AttachIcon;
