import React, { useContext, useState, useEffect } from 'react';
import ChatBot from './react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import Loader from 'react-loader-spinner';
import 'firebase/database';
import 'firebase/storage';
import imageCompression from 'browser-image-compression';
import axios from 'axios';
import moment from 'moment';

import { FirebaseContext } from './firebase';
import HeaderComponent from './HeaderComponent';
import Thanks from './thanks';
import getSteps from './steps';
import { getIssue, getMessages } from './issues';
import { dateFormat } from './dateFormat';

import styles from './styles';
import theme from './theme';
import 'firebase/messaging';

const notFoundText = 'The object is not registered in Polypie AI system.';
const notActiveText = 'The PieCode agent is not available. Please check it out later.';
const noAgentText = 'The PieCode agent is not available.';

function App() {
  const firebase = useContext(FirebaseContext);

  const db = firebase.database();
  const storage = firebase.storage();
  const fsMessage = firebase.messaging.isSupported() ? firebase.messaging() : undefined;

  const [status, setStatus] = useState('loading');
  const [machine, setMachine] = useState();
  const [userInfo, setUserInfo] = useState({ lat: null, long: null });
  const [steps, setSteps] = useState();
  const [startTime, setStartTime] = useState();
  const [bannerImages, setBannerImages] = useState([]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setUserInfo({
          lat: position.coords.latitude,
          long: position.coords.longitude
        });
      });
    }
  }, []);

  useEffect(() => {
    const machineId = window.location.pathname.substring(1);

    if (machineId !== '') {
      const ref = db.ref(`/machine/${machineId}`);
      ref.once('value', (snapshot) => {
        setStartTime(Date.now());

        const data = snapshot.val();
        setBannerImages([
          { type: 'video', url: data?.video },
          { type: 'externalVideo', url: data?.externalVideo },
          ...(data?.image || []).map((i) => ({ type: 'image', url: i })),
        ]);
        if (data) {
          if (data.activated) {
            if (data.agentType === '') {
              setStatus('noAgent');
            } else {
              const steps = getSteps(data);
              setSteps(steps);
              setStatus('loaded');

              data.key = machineId;
              setMachine(data);
            }
          } else {
            setStatus('notActive');
          }
        } else {
          setStatus('notFound');
        }
      });
    } else {
      setStatus('notFound');
    }
  }, [db]);

  const sendMail = (email, json, machine, image1, image2, image3) => {
    axios
      .get(
        'https://us-central1-piecode-7292b.cloudfunctions.net/sendMailBot?jsonData=' +
        encodeURIComponent(json) +
        '&date=' +
        new Date().toString() +
        '&sentToMail=' +
        email +
        '&modelName=' +
        encodeURIComponent(machine.modelName) +
        '&objectName=' +
        encodeURIComponent(machine.title) +
        '&objectId=' +
        machine.key +
        '&detailLocation=' +
        machine.detailServiceLocation +
        '&image1=' +
        encodeURIComponent(image1) +
        '&image2=' +
        encodeURIComponent(image2) +
        '&image3=' +
        encodeURIComponent(image3) +
        '&userName=' +
        encodeURIComponent(machine.reportedBy)
      )
      .then((response) => {
        console.log('email', response);
      });
  };

  const handleEnd = (props) => {
    const issue = getIssue(props, machine, userInfo);
    const millis = Date.now() - startTime;
    issue.totalTime = Math.floor(millis / 1000);//need to check here

    const issueKey = db.ref(`issues`).push(issue).key;

    //

    const messages = getMessages(props, machine);

    messages.map((message) => {
      db.ref(`/issues/${issueKey}/chat`).push(message);
      return null;
    });

    // User notifications
    const userNotif = {
      createdDate: dateFormat(new Date()),
      isRead: false,
      machineID: machine.key,
      issueID: issueKey,
      title:
        machine.modelName +
        ' - ' +
        machine.title +
        ' got new feedback from an anonymous user',
      type: 'issue',
      deviceType: 'web',
    };

    db.ref(`userNotifications/${machine.clientID}`).push(userNotif);

    //

    const activity = {
      timestamp: Date.now(),
      createdDate: dateFormat(new Date()),
      machineID: machine.key,
      clientID: machine.clientID,
      issueID: issueKey,
      message: machine.title + ' got a new report',
    };

    db.ref(`issue-activities`).push(activity);

    // Send Email
    const filtered = messages
      .filter((m) => m.type !== 'image')
      .map((m) => ({
        rawMessage: m.message,
        senderId: m.senderId,
      }));

    const json = JSON.stringify(filtered);
    const image1 = JSON.stringify(
      issue.image.length > 0 ? issue.image[0] : null
    );
    const image2 = JSON.stringify(
      issue.image.length > 1 ? issue.image[1] : null
    );
    const image3 = JSON.stringify(
      issue.image.length > 2 ? issue.image[2] : null
    );

    sendMail(machine.contactPersonEmail, json, machine, image1, image2, image3);

    const issuesList = issue.issues.map((i) => i.option);

    if (machine.members) {
      machine.members.map((m) => {
        let _sendMail_d = false;
        let _sendMail_t = false;
        if (issuesList.includes(m.issue.label)) {
          const momentObj = moment.utc(issue.createdDate, 'MM-DD-YYYY hh:mm A').local();
          const day = momentObj.day(); 
          const hours = momentObj.hours(); 
          

          if (
            parseInt(m.weekFrom.value) <= parseInt(m.weekTo.value) &&
            parseInt(m.weekFrom.value) <= day &&
            day <= parseInt(m.weekTo.value)
          ) {
            _sendMail_d = true;
          } else if (parseInt(m.weekFrom.value) > parseInt(m.weekTo.value)) {
            if (
              parseInt(m.weekFrom.value) <= day <= 7 ||
              1 <= day <= parseInt(m.weekTo.value)
            ) {
              _sendMail_d = true;
            } else {
              _sendMail_d = false;
            }
          } else {
            _sendMail_d = false;
          }

          if (
            parseInt(m.timeFrom.value) <= parseInt(m.timeTo.value) &&
            parseInt(m.timeFrom.value) <= hours &&
            hours <= parseInt(m.timeTo.value)
          ) {
            _sendMail_t = true;
          } else if (parseInt(m.timeFrom.value) > parseInt(m.timeTo.value)) {
            if (
              parseInt(m.timeFrom.value) <= hours <= 24 ||
              0 <= hours <= parseInt(m.timeTo.value)
            ) {
              _sendMail_t = true;
            } else {
              _sendMail_t = false;
            }
          } else {
            _sendMail_t = false;
          }
        }

        if (_sendMail_d && _sendMail_t) {
          sendMail(m.email, json, machine, image1, image2, image3);
        }

        return null;
      });
    }

    setTimeout(() => {
      setStatus('sayThanks');
    }, 1000);
  };

  const handleFireBaseUpload = (image, submitImage) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(image, options).then((compressedFile) => {
      const uploadTask = storage
        .ref(`/BotImages/${compressedFile.name}`)
        .put(compressedFile);

      uploadTask.on(
        'state_changed',
        (snapShot) => {
          //takes a snap shot of the process as it is happening
          console.log(snapShot);
        },
        (err) => {
          //catches the errors
          console.log(err);
        },
        async () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          const imageUrl = await storage
            .ref('BotImages')
            .child(compressedFile.name)
            .getDownloadURL();
          submitImage(imageUrl);
        }
      );
    });
  };

  if (status === 'loading') {
    return (
      <div style={styles.emptyScreenContainer}>
        <Loader type="ThreeDots" color="#2d8ce1" height={50} width={50} />
      </div>
    );
  }

  if (status === 'notFound') {
    return (
      <div style={styles.emptyScreenContainer}>
        <div>{notFoundText}</div>
      </div>
    );
  }

  if (status === 'notActive') {
    return (
      <div style={styles.emptyScreenContainer}>
        <div>{notActiveText}</div>
      </div>
    );
  }

  if (status === 'noAgent') {
    return (
      <div style={styles.emptyScreenContainer}>
        <div>{noAgentText}</div>
      </div>
    );
  }

  if (status === 'sayThanks') {
    return (
      <div style={styles.emptyScreenContainer}>
        <Thanks />
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <ThemeProvider theme={theme}>
        <ChatBot
          // Customisation
          headerTitle="Virtual Assistance"
          botAvatar={require('./assets/imageBot.png')}
          headerComponent={
            <HeaderComponent bannerImages={bannerImages} machine={machine} />
          }
          botDelay={1500}
          // Styles
          className="customRoot"
          width={window.innerWidth > 800 ? '60vh' : '100%'}
          contentStyle={styles.contentStyle}
          bubbleStyle={styles.bubbleStyle}
          avatarStyle={styles.avatarStyle}
          bubbleOptionStyle={styles.bubbleOptionStyle}
          inputStyle={styles.inputStyle}
          floatingStyle={styles.floatingStyle}
          footerStyle={styles.footerStyle}
          // Steps
          steps={steps}
          floating
          opened
          handleEnd={handleEnd}
          handleImageUpload={handleFireBaseUpload}
        />
      </ThemeProvider>
    </div>
  );
}

export default App;
