import React, { Component } from 'react';

import Bubble from '../text/Bubble';
import Image from '../text/Image';
import ImageContainer from '../text/ImageContainer';
import Loading from '../common/Loading';
import TextStepContainer from '../text/TextStepContainer';

import { convertLocal } from "../../../dateFormat";

class ImageStep extends Component {
  state = {
    loading: true
  };

  handleOnImgLoad() {
  	this.setState({ loading : false });
  	this.props.triggerNextStep();
  }

  renderImage() {
  	const { step, imageUploading } = this.props;
  	const { loading } = this.state;
  	const isLoading = imageUploading || loading;

  	return (
  	  <div>
  	  	{isLoading && <Loading />}
  	    {step.value && 
          <img
  	  	    alt={step.key}
  	 	      src={step.value}
  	 	      style={{
  	 	       width: isLoading ? null : '100%',
  	 	       visibility: isLoading ? 'hidden' : null
  	 	      }}
  	 	      onLoad={() => this.handleOnImgLoad()}
  	  	 />
        }
        <div style={{ padding: 6 }}>{step.message}</div>
  	  </div>
  	);
  }

  _editText(userText) {
    const { step, editText } = this.props;
    if (userText) {
      return editText(step);
    }
    return null;
  }

  render() {
    const {
      step,
      isFirst,
      isLast,
      avatarStyle,
      bubbleStyle,
      hideBotAvatar,
      hideUserAvatar
    } = this.props;
    const { avatar, user } = step;

    const showAvatar = user ? !hideUserAvatar : !hideBotAvatar;
    const userText = step.id.startsWith('u_');

    return(
      <TextStepContainer className={`rsc-ts ${user ? 'rsc-ts-user' : 'rsc-ts-bot'}`} user={user}>
        <ImageContainer className="rsc-ts-image-container" user={user}>
          {isFirst && showAvatar && (
            <Image
              className="rsc-ts-image"
              style={avatarStyle}
              showAvatar={showAvatar}
              user={user}
              src={avatar}
              alt="avatar"
            />
          )}
        </ImageContainer>
        <table style={{ maxWidth: '77%', minWidth: '50%' }} onClick={() => this._editText(userText) }>
          {isFirst && 
            <tbody>
              <tr>
                <td style={{ fontSize: 10, padding: 2, marginLeft: user ? 0 : 10, marginRight: user ? 15 : 0, marginBottom: 0, float: user ? 'right' : 'left' }}>
                  {convertLocal(step.date)}
                </td>
              </tr>
            </tbody>
          }
          <tbody>
            <tr>
              <td >
                <Bubble
                  className="rsc-ts-bubble"
                  user={user}
                  showAvatar={showAvatar}
                  isFirst={isFirst}
                  isLast={isLast}
                  style={{ ...bubbleStyle, float: user ? 'right' : 'left', marginBottom: 0 }}
                >
                  {this.renderImage()}
                </Bubble>
              </td>
            </tr>
          </tbody>
          {userText && <tbody>
            <tr>
              <td style={{ fontSize: 10, padding: 2, marginLeft: 20, marginBottom: 15, float: user ? 'right' : 'left', fontWeight: 'bold' }}>Tap to Edit</td>
            </tr>
          </tbody>}
        </table>
      </TextStepContainer>
   );
 }
}

export default ImageStep;
