const theme = {
  background: '#f5f8fb',
  // fontFamily: 'Helvetica Neue',
  headerBgColor: 'white',
  headerFontColor: '#2d8ce1',
  headerFontSize: '15px',
  botBubbleColor: '#dceeff',
  botFontColor: '#2d8ce1',
  userBubbleColor: '#2d8ce1',
  userFontColor: 'white',
  linkColor: '#2d8ce1'
};

export default theme;
