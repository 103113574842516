import { commonFlow } from './common';

export default function getGCS(data) {
  let options = [];
  let responses = [];
  let userInputs = [];
  let followUps = [];

  // Arrays will mutate
  commonFlow(data, options, responses, userInputs, followUps);

  return [
    {
      id: 'start',
      message: data.greetings && data.greetings !== "" ? data.greetings : "Hello! How can I help?",
      trigger: 'options',
    },
    {
      id: 'options',
      options: options
    },
    ...responses,
    ...userInputs,
    ...followUps,
    {
      id: 'penultimate',
      message: "Can I help you with anything else?",
      trigger: 'options_2'
    },
    {
      id: 'options_2',
      options: [
        { value: 1, label: 'Yes, I would like to see the options.', trigger: 'options' },
        { value: 2, label: 'No, I would like to end the chat.', trigger: 'departing_thanks' },
        { value: 3, label: 'Start over.', trigger: () => window.location.reload() }
      ],
    },
    {
      id: 'departing_thanks',
      message: data.botResponse[0],
      trigger: 'bye'
    },
    {
      id: 'bye',
      message: 'Bye!',
      end: true
    }
  ];
}
