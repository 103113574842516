const styles = {
  container: {
    marginHorizontal: 'auto',
  },
  contentStyle: {},
  bubbleStyle: {
    maxWidth: 'none',
  },
  avatarStyle: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: '50%',
  },
  inputStyle: {
    resize: 'none',
  },
  bubbleOptionStyle: {
    // backgroundColor: '#91bad6',
    marginVertical: 5,
  },
  headerContainer: {
    backgroundColor: 'white',
    height: 56,
    display: 'inline-flex',
    width: '100%',
  },
  logoImage: {
    height: 40,
    minWidth: 40,
    margin: 10,
    display: 'inline',
  },
  headerText: {
    margin: 10,
    alignSelf: 'center',
  },
  emptyScreenContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  floatingStyle: {},
  thankContainer: {
    display: 'table',
    justifyContent: 'center',
    background: '#28A2C4',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
  },
  thankIcon: {
    height: '50vh',
    margin: 10,
  },
  thankText: {
    textAlign: 'center',
    color: '#fff',
    fontSize: '1.2em',
    padding: '2em',
  },
  footerStyle: {},
};

export default styles;
