import styled from 'styled-components';

const ExpandCollapseIcon = styled.a`
  cursor: pointer;
  margin-top: ${({ detailsView }) => detailsView ? '-35px' : '-5px'};
  display: flex;
  flex-direction: row-reverse;
  margin-right: 49%;
`;

export default ExpandCollapseIcon;
