import { dateFormat } from '../dateFormat';
import { ImageBot, ImageUser } from '../constants'

export function getIssue(props, machine, userInfo) {

  const { renderedSteps, steps } = props;

  const feedbackText = steps['u_feedback'] ? steps['u_feedback'].message : null;
  const othersText = steps['u_others'] ? steps['u_others'].message : null;
  const priority = steps['u_priority'] ? steps['u_priority'].message : null;

  const comments = renderedSteps.filter(item => item.id.startsWith('u_') && !item.image).map(item => item.message);
  const sendimages = renderedSteps.filter(item => item.id.startsWith('u_') && item.image).map(item => item.value);
  const newIssueArray = renderedSteps.filter(item => item.id === 'options').map(item => { return { option: item.message, resolved: false } });

  const issue = {
    clientId: machine.clientID,
    createdDate: dateFormat(new Date()),
    updatedDate: dateFormat(new Date()),
    description: feedbackText + othersText,
    detailLocation: "",
    eventID: "",
    id: "",
    comments: comments,
    image: sendimages,
    isClosed: false,
    isRead: true,
    pieCodeName: machine.title,
    businessName: machine.businessName,
    contactPersonName: machine.contactPersonName,
    issues: newIssueArray,
    location: machine.location,
    lat: userInfo.lat || machine.lat || null,
    long:userInfo.long || machine.long || null,
    pieCodeId: machine.key,
    pieCodeImages: machine.image ? machine.image : [],
    name: machine.title,
    priority: priority ? priority : "High",
    status: "Open",
    reportedBy: "anonymous web user",
    thumb: "",
    type: machine.type
  };

  return issue;
}

export function getMessages(props, machine) {

  let messages = [];

  const { renderedSteps } = props;

  for (let i = 0; i < renderedSteps.length; i++) {
    let s = renderedSteps[i];
    let type = 'text';
    let isSelected = false;
    let senderId = 'bot';

    if (s.id.startsWith('u_')) {
      senderId = 'user';
    }

    if (s.id.startsWith('options')) {
      isSelected = true;
      senderId = 'user';
    }

    if (s.image) {
      messages.push({
        date: s.date,
        isSelected,
        message: s.value,
        senderId,
        senderImage: senderId === 'bot' ? ImageBot : ImageUser, 
        timestamp: s.timestamp,
        type: 'image'
      });
    }

    messages.push({
      date: s.date,
      isSelected,
      message: s.message,
      senderId,
      senderImage: senderId === 'bot' ? ImageBot : ImageUser, 
      timestamp: s.timestamp,
      type
    });
  }

  return messages;
}
