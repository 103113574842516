export const ImageLoading =
  "https://firebasestorage.googleapis.com/v0/b/piecode-7292b.appspot.com/o/CommonImages%2FSpinner-1s-200px.gif?alt=media&token=15762762-ef26-48cc-9d80-c40ee1f85f17";
export const ImageLogo =
  "https://firebasestorage.googleapis.com/v0/b/piecode-7292b.appspot.com/o/CommonImages%2F1.png?alt=media&token=603c905c-a9a9-4999-b021-d8ed7fef7424";
export const ImageJenny =
  "https://firebasestorage.googleapis.com/v0/b/piecode-7292b.appspot.com/o/CommonImages%2FJennyWang.JPG?alt=media&token=2ac83f39-deaa-40d0-94c6-981c42b2db51";
export const ImageBot =
"https://firebasestorage.googleapis.com/v0/b/piecode-7292b.appspot.com/o/CommonImages%2F1.png?alt=media&token=603c905c-a9a9-4999-b021-d8ed7fef7424";
export const ImageUser =
  "https://firebasestorage.googleapis.com/v0/b/piecode-7292b.appspot.com/o/CommonImages%2Fuser.png?alt=media&token=eea406c5-f7f7-4e9e-acaf-b48f0415e013";
export const IphoneStatic =
  "https://firebasestorage.googleapis.com/v0/b/piecode-7292b.appspot.com/o/CommonImages%2Fiphone-static.jpg?alt=media&token=5caa47c6-b910-454d-84d2-22024f416674";
export const iphoneFrame =
  "https://firebasestorage.googleapis.com/v0/b/piecode-7292b.appspot.com/o/CommonImages%2FPSX_20191108_092708.jpg?alt=media&token=90b4b40f-73d1-476b-9505-70e392ccdaa0";
export const customerService =
  "https://firebasestorage.googleapis.com/v0/b/piecode-7292b.appspot.com/o/CommonImages%2F3.jpg?alt=media&token=211d494a-94e0-43e2-9684-b692d994121d";
export const serviceRequest =
  "https://firebasestorage.googleapis.com/v0/b/piecode-7292b.appspot.com/o/CommonImages%2FService%20Request.png?alt=media&token=91ed19af-e31f-41c5-9e7f-0ac901e94a4a";
export const shoppingAI =
  "https://firebasestorage.googleapis.com/v0/b/piecode-7292b.appspot.com/o/CommonImages%2F1.jpg?alt=media&token=aa3a4ba2-0e58-4f0e-8b90-d0e6c29d0864";
export const restaurantAI =
  "https://firebasestorage.googleapis.com/v0/b/piecode-7292b.appspot.com/o/CommonImages%2F2.jpg?alt=media&token=c30c3f43-85e8-426e-9e02-44be83209483";
export const iphone5sFrame =
  "https://firebasestorage.googleapis.com/v0/b/piecode-7292b.appspot.com/o/CommonImages%2Fiphone5sFrame.jpg?alt=media&token=21841ddb-ddc1-4bdd-a48f-fb916e7dbc3a";
export const MapStatic =
  "https://firebasestorage.googleapis.com/v0/b/piecode-7292b.appspot.com/o/Web%20static%20map%20view.png?alt=media&token=10f929d3-16e0-43c1-984f-15ea8a0cf366";
export const AnonymousSenderId = "12345"
