import React, { useState } from 'react';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
// import ImageComponent from './ImageComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default function Carousel({ images }) {
  const [popupUrl, setPopupUrl] = useState(null);

  const getId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  images = images.filter(({ url }) => !!url);
  let singleSlide = images.length === 1;
  return (
    <>
      <ReactCarousel
        showIndicators={false}
        infiniteLoop={!singleSlide}
        showArrows={true}
        swipeable={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={false}
        emulateTouch={true}>
        {images
          .filter((i) => !!i.url)
          .map((i) => (
            <div key={i} style={{ height: 200 }}
              onClick={() => {
                if (i.type === 'image')
                  setPopupUrl(i.url);
              }}>
              {i.type === 'image' && (
                <img
                  style={{ objectFit: 'contain', height: '100%' }}
                  alt={i.url}
                  src={i.url}
                  onClick={() => setPopupUrl(i.url)}
                />
              )}
              {i.type === 'video' && (
                <video
                  src={i.url}
                  style={{ height: '100%', width: '100%' }}
                  controls="controls"
                  preload="auto"
                />
              )}
              {i.type === 'externalVideo' && i.url && (
                <iframe
                  src={`https://www.youtube.com/embed/${getId(i.url)}`}
                  title="Embedded external video"
                  width="560"
                  height="200"
                  allowFullScreen
                  allow='encrypted-media'
                  frameBorder='0'
                  
                />
              )}
            </div>
          ))}
      </ReactCarousel>
      {popupUrl && (
        <dialog
          className="dialog"
          open
        >
          <span className="dialog-close"
            onClick={() => setPopupUrl(null)}>×</span>
          <img
            className="dialog-img"
            src={popupUrl}
            alt={popupUrl}
          />
        </dialog>
      )}
    </>
  );
}
