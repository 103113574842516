export const dateFormat = d => {
  var utc = d.getTime() + d.getTimezoneOffset() * 60000;
  var dt = new Date(utc);
  return `${(dt.getMonth() + 1).toString().padStart(2, "0")}-${dt
    .getDate()
    .toString()
    .padStart(2, "0")}-${dt
      .getFullYear()
      .toString()
      .padStart(4, "0")} ${((dt.getHours() + 24) % 12 ||
        (12)).toString().padStart(2, "0")}:${dt
          .getMinutes()
          .toString()
          .padStart(2, "0")} ${
    dt.getHours() > 11 ? "PM" : "AM".toString().padStart(2, "0")
    }`;
  };

export const convertLocal = date => {
  let dt = date.split("-").join("/");
  dt = Date.parse(dt + " UTC")
  dt = new Date(dt)
  return `${(dt.getMonth() + 1).toString().padStart(2, "0")}-${dt
    .getDate()
    .toString()
    .padStart(2, "0")}-${dt
    .getFullYear()
    .toString()
    .padStart(4, "0")} ${(dt.getHours() + 24) % 12 ||
    (12).toString().padStart(2, "0")}:${dt
    .getMinutes()
    .toString()
    .padStart(2, "0")} ${
    dt.getHours() > 11 ? "PM" : "AM".toString().padStart(2, "0")
  }`;
};

export const compatibleDate = date => {
 return date.split("-").join("/")
}
