import React, { createContext } from 'react';
import app from 'firebase/app';

const FirebaseContext = createContext(null)
export { FirebaseContext }

export default ({ children }) => {
  if (!app.apps.length) {
    app.initializeApp({
      apiKey: "AIzaSyBxayXE0vFTpMqm_T3srDvwqVSlPWiAgt8",
      authDomain: "piecode-7292b.firebaseapp.com",
      databaseURL: "https://piecode-7292b.firebaseio.com",
      projectId: "piecode-7292b",
      storageBucket: "piecode-7292b.appspot.com",
      messagingSenderId: "466254566467",
      appId: "1:466254566467:web:d50b66e07a2ee0cc"
    })
  }
  return (
    <FirebaseContext.Provider value={ app }>
      { children }
    </FirebaseContext.Provider>
  )
}
