import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Random from 'random-id';
import {
  CustomStep,
  OptionsStep,
  TextStep,
  ImageStep,
} from './steps_components';
import schema from './schemas/schema';
import * as storage from './storage';
import {
  ChatBotContainer,
  Content,
  Header,
  HeaderTitle,
  // HeaderIcon,
  ExpandCollapseIcon,
  FloatButton,
  FloatingIcon,
  Footer,
  Input,
  SubmitButton,
} from './components';
import Recognition from './recognition';
import {
  // CloseIcon, 
  ChatIcon, SubmitIcon, MicIcon, AttachIcon, UpIcon, DownIcon
} from './icons';
import { isMobile } from './utils';
import { speakFn } from './speechSynthesis';

import { dateFormat } from '../dateFormat';

class ChatBot extends Component {
  /* istanbul ignore next */
  constructor(props) {
    super(props);

    this.content = null;
    this.input = null;

    this.supportsScrollBehavior = false;

    this.setContentRef = (element) => {
      this.content = element;
    };

    this.setInputRef = (element) => {
      this.input = element;
    };

    this.state = {
      renderedSteps: [],
      previousSteps: [],
      currentStep: {},
      previousStep: {},
      steps: {},
      disabled: true,
      opened: props.opened || !props.floating,
      inputValue: '',
      inputInvalid: false,
      speaking: false,
      recognitionEnable: props.recognitionEnable && Recognition.isSupported(),
      defaultUserSettings: {},
      imageUploading: false,
      inputImage: undefined,
      editingStep: null,
      detailsView: true
    };

    this.speak = speakFn(props.speechSynthesis);

    this.fileUpload = React.createRef();
  }

  componentDidMount() {
    const { steps } = this.props;
    const {
      botDelay,
      botAvatar,
      cache,
      cacheName,
      customDelay,
      enableMobileAutoFocus,
      userAvatar,
      userDelay,
    } = this.props;
    const chatSteps = {};

    const defaultBotSettings = { delay: botDelay, avatar: botAvatar };
    const defaultUserSettings = {
      delay: userDelay,
      avatar: userAvatar,
      hideInput: false,
      hideExtraControl: false,
    };
    const defaultCustomSettings = { delay: customDelay };

    for (let i = 0, len = steps.length; i < len; i += 1) {
      const step = steps[i];
      let settings = {};

      if (step.user) {
        settings = defaultUserSettings;
      } else if (step.message || step.asMessage) {
        settings = defaultBotSettings;
      } else if (step.component) {
        settings = defaultCustomSettings;
      }
      chatSteps[step.id] = Object.assign({}, settings, schema.parse(step));
    }

    schema.checkInvalidIds(chatSteps);

    const firstStep = steps[0];
    firstStep.date = dateFormat(new Date());
    firstStep.timestamp = Date.now();

    if (firstStep.message) {
      const { message } = firstStep;
      firstStep.message = typeof message === 'function' ? message() : message;
      chatSteps[firstStep.id].message = firstStep.message;
    }

    const { recognitionEnable } = this.state;
    const { recognitionLang } = this.props;

    if (recognitionEnable) {
      this.recognition = new Recognition(
        this.onRecognitionChange,
        this.onRecognitionEnd,
        this.onRecognitionStop,
        recognitionLang
      );
    }

    this.supportsScrollBehavior =
      'scrollBehavior' in document.documentElement.style;

    if (this.content) {
      this.content.addEventListener('DOMNodeInserted', this.onNodeInserted);
      window.addEventListener('resize', this.onResize);
    }

    const {
      currentStep,
      previousStep,
      previousSteps,
      renderedSteps,
    } = storage.getData(
      {
        cacheName,
        cache,
        firstStep,
        steps: chatSteps,
      },
      () => {
        // focus input if last step cached is a user step
        this.setState({ disabled: false }, () => {
          if (enableMobileAutoFocus || !isMobile()) {
            if (this.input) {
              this.input.focus();
            }
          }
        });
      }
    );

    this.setState({
      currentStep,
      defaultUserSettings,
      previousStep,
      previousSteps,
      renderedSteps,
      steps: chatSteps,
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { opened, toggleFloating } = props;
    if (
      toggleFloating !== undefined &&
      opened !== undefined &&
      opened !== state.opened
    ) {
      return {
        ...state,
        opened,
      };
    }
    return state;
  }

  componentWillUnmount() {
    if (this.content) {
      this.content.removeEventListener('DOMNodeInserted', this.onNodeInserted);
      window.removeEventListener('resize', this.onResize);
    }
  }

  onNodeInserted = (event) => {
    const { currentTarget: target } = event;
    const { enableSmoothScroll } = this.props;

    if (enableSmoothScroll && this.supportsScrollBehavior) {
      target.scroll({
        top: target.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      target.scrollTop = target.scrollHeight;
    }

    let nodeList = target.querySelectorAll('img.rsc-ts-image');
    if (nodeList.length === 0) {
      nodeList = target.querySelectorAll('div.rsc-ts');
    }
    if (nodeList.length > 0) {
      nodeList[nodeList.length - 1].scrollIntoView();
    }

  };

  onResize = () => {
    this.content.scrollTop = this.content.scrollHeight;
  };

  onRecognitionChange = (value) => {
    this.setState({ inputValue: value });
  };

  onRecognitionEnd = () => {
    this.setState({ speaking: false });
    this.handleSubmitButton();
  };

  onRecognitionStop = () => {
    this.setState({ speaking: false });
  };

  onValueChange = (event) => {
    const text = event.target.value;
    this.setState({ inputValue: text.substring(0, 500) });
  };

  getTriggeredStep = (trigger, value) => {
    const steps = this.generateRenderedStepsById();
    return typeof trigger === 'function' ? trigger({ value, steps }) : trigger;
  };

  getStepMessage = (message) => {
    const { previousSteps } = this.state;
    const lastStepIndex =
      previousSteps.length > 0 ? previousSteps.length - 1 : 0;
    const steps = this.generateRenderedStepsById();
    const previousValue = previousSteps[lastStepIndex].value;
    return typeof message === 'function'
      ? message({ previousValue, steps })
      : message;
  };

  generateRenderedStepsById = () => {
    const { previousSteps } = this.state;
    const steps = {};

    for (let i = 0, len = previousSteps.length; i < len; i += 1) {
      const { id, message, value, metadata } = previousSteps[i];

      steps[id] = {
        id,
        message,
        value,
        metadata,
      };
    }

    return steps;
  };

  triggerNextStep = (data) => {
    const { enableMobileAutoFocus } = this.props;
    const {
      defaultUserSettings,
      previousSteps,
      renderedSteps,
      steps,
      inputValue,
    } = this.state;
    let { currentStep, previousStep } = this.state;
    const isEnd = currentStep.end;

    let contactInfo;
    if (currentStep.id === 'u_order_1') {
      contactInfo = renderedSteps.find((s) => s.id === 'u_order_2');
    }

    if (data && data.value) {
      currentStep.value = data.value;
    }
    if (data && data.hideInput) {
      currentStep.hideInput = data.hideInput;
    }
    if (data && data.hideExtraControl) {
      currentStep.hideExtraControl = data.hideExtraControl;
    }
    if (data && data.trigger) {
      currentStep.trigger = this.getTriggeredStep(data.trigger, data.value);
    }

    if (isEnd) {
      this.handleEnd();
    } else if (currentStep.options && data) {
      const option = currentStep.options.filter(
        (o) => o.value === data.value
      )[0];
      const trigger = this.getTriggeredStep(option.trigger, currentStep.value);
      delete currentStep.options;

      // replace choose option for user message
      currentStep = Object.assign(
        {},
        currentStep,
        option,
        defaultUserSettings,
        {
          user: true,
          message: option.label,
          trigger,
        }
      );

      renderedSteps.pop();
      previousSteps.pop();
      renderedSteps.push(currentStep);
      previousSteps.push(currentStep);

      this.setState({
        currentStep,
        renderedSteps,
        previousSteps,
      });
    } else if (currentStep.trigger) {
      if (currentStep.replace) {
        renderedSteps.pop();
      }

      let trigger = this.getTriggeredStep(
        currentStep.trigger,
        currentStep.value
      );

      if (contactInfo && trigger === 'f_order_2') {
        trigger = 'f_order_3';
      }

      let nextStep = Object.assign({}, steps[trigger]);
      nextStep.date = dateFormat(new Date());
      nextStep.timestamp = Date.now();

      if (nextStep.message) {
        nextStep.message = this.getStepMessage(nextStep.message);
      } else if (nextStep.update) {
        const updateStep = nextStep;
        nextStep = Object.assign({}, steps[updateStep.update]);

        if (nextStep.options) {
          for (let i = 0, len = nextStep.options.length; i < len; i += 1) {
            nextStep.options[i].trigger = updateStep.trigger;
          }
        } else {
          nextStep.trigger = updateStep.trigger;
        }
      }

      let _inputValue = inputValue;

      if (currentStep.id === 'options_order_3' && currentStep.value === 2) {
        const lastOrderReverseIdx = renderedSteps
          .slice()
          .reverse()
          .findIndex((s) => s.id === 'u_order_1');

        const lastOrderIdx = renderedSteps.length - 1 - lastOrderReverseIdx;

        _inputValue = renderedSteps[lastOrderIdx].message;
        renderedSteps.splice(lastOrderIdx, 1);
      }

      nextStep.key = Random(24);

      previousStep = currentStep;
      currentStep = nextStep;

      this.setState(
        { renderedSteps, currentStep, previousStep, inputValue: _inputValue },
        () => {
          if (nextStep.user) {
            this.setState({ disabled: false }, () => {
              if (enableMobileAutoFocus || !isMobile()) {
                if (this.input) {
                  this.input.focus();
                }
              }
            });
          } else {
            renderedSteps.push(nextStep);
            previousSteps.push(nextStep);

            this.setState({ renderedSteps, previousSteps });
          }
        }
      );
    }

    const { cache, cacheName } = this.props;
    if (cache) {
      setTimeout(() => {
        storage.setData(cacheName, {
          currentStep,
          previousStep,
          previousSteps,
          renderedSteps,
        });
      }, 300);
    }
  };

  handleEnd = () => {
    const { handleEnd } = this.props;

    if (handleEnd) {
      const { previousSteps } = this.state;

      const renderedSteps = previousSteps.map((step) => {
        const { id, message, value, metadata, image, date, timestamp } = step;

        return {
          id,
          message,
          value,
          metadata,
          image,
          date,
          timestamp,
        };
      });

      const steps = [];

      for (let i = 0, len = previousSteps.length; i < len; i += 1) {
        const {
          id,
          message,
          value,
          metadata,
          image,
          date,
          timestamp,
        } = previousSteps[i];

        steps[id] = {
          id,
          message,
          value,
          metadata,
          image,
          date,
          timestamp,
        };
      }

      const values = previousSteps
        .filter((step) => step.value)
        .map((step) => step.value);

      handleEnd({ renderedSteps, steps, values });
    }
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  addImageToInput = (e) => {
    const image =
      e.target.files && e.target.files.length > 0
        ? e.target.files[0]
        : undefined;

    if (image !== undefined) {
      let imgBase64 = '';
      this.getBase64(image, (result) => {
        imgBase64 = result;

        this.setState({
          inputImage: image,
          inputImageBase64: imgBase64,
        });
      });
    }
  };

  submitUserImage = () => {
    const { handleImageUpload } = this.props;
    const {
      defaultUserSettings,
      previousSteps,
      renderedSteps,
      inputImage,
      inputValue,
    } = this.state;
    let { currentStep } = this.state;

    currentStep = Object.assign({}, currentStep, { image: true });
    renderedSteps.push(currentStep);
    previousSteps.push(currentStep);

    const submitImage = (imgUrl) => {
      const step = {
        message: inputValue,
        value: imgUrl,
        image: true,
      };

      currentStep = Object.assign({}, defaultUserSettings, currentStep, step);

      renderedSteps.pop();
      renderedSteps.push(currentStep);

      previousSteps.pop();
      previousSteps.push(currentStep);

      this.setState(
        {
          currentStep,
          renderedSteps,
          previousSteps,
          inputValue: '',
          imageUploading: false,
          inputImage: undefined,
        },
        () => {
          if (this.input) {
            this.input.blur();
          }
        }
      );
    };

    if (handleImageUpload) {
      this.setState({
        imageUploading: true,
        currentStep,
        renderedSteps,
        previousSteps,
        disabled: true,
        inputImage: undefined,
      });
      handleImageUpload(inputImage, submitImage);
    }
  };

  isInputValueEmpty = () => {
    const { inputValue } = this.state;
    return !inputValue || inputValue.length === 0;
  };

  isLastPosition = (step) => {
    const { renderedSteps } = this.state;
    const { length } = renderedSteps;
    const stepIndex = renderedSteps.map((s) => s.key).indexOf(step.key);

    if (length <= 1 || stepIndex + 1 === length) {
      return true;
    }

    const nextStep = renderedSteps[stepIndex + 1];
    const hasMessage = nextStep.message || nextStep.asMessage;

    if (!hasMessage) {
      return true;
    }

    const isLast = step.user !== nextStep.user;
    return isLast;
  };

  isFirstPosition = (step) => {
    const { renderedSteps } = this.state;
    const stepIndex = renderedSteps.map((s) => s.key).indexOf(step.key);

    if (stepIndex === 0) {
      return true;
    }

    const lastStep = renderedSteps[stepIndex - 1];
    const hasMessage = lastStep.message || lastStep.asMessage;

    if (!hasMessage) {
      return true;
    }

    const isFirst = step.user !== lastStep.user;
    return isFirst;
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmitButton();
    }
  };

  handleSubmitButton = () => {
    const { speaking, recognitionEnable, inputImage, editingStep } = this.state;

    if ((this.isInputValueEmpty() || speaking) && recognitionEnable) {
      this.recognition.speak();
      if (!speaking) {
        this.setState({ speaking: true });
      }
      return;
    }

    if (editingStep) {
      if (inputImage) {
        this.editUserImage();
      } else {
        this.editUserMessage();
      }
    } else if (inputImage) {
      this.submitUserImage();
    } else {
      this.submitUserMessage();
    }
  };

  submitUserMessage = () => {
    const {
      defaultUserSettings,
      inputValue,
      previousSteps,
      renderedSteps,
    } = this.state;
    let { currentStep } = this.state;

    const isInvalid = currentStep.validator && this.checkInvalidInput();

    if (!isInvalid) {
      const step = {
        message: inputValue,
        value: inputValue,
      };

      currentStep = Object.assign({}, defaultUserSettings, currentStep, step);

      renderedSteps.push(currentStep);
      previousSteps.push(currentStep);

      this.setState(
        {
          currentStep,
          renderedSteps,
          previousSteps,
          disabled: true,
          inputValue: '',
        },
        () => {
          if (this.input) {
            this.input.blur();
          }
        }
      );
    }
  };

  editUserMessage = () => {
    const {
      editingStep,
      inputValue,
      renderedSteps,
      previousSteps,
    } = this.state;
    editingStep.message = inputValue;

    const idx_r = renderedSteps.findIndex((step) => step.id === editingStep.id);
    const idx_p = previousSteps.findIndex((step) => step.id === editingStep.id);

    renderedSteps.splice(idx_r, 1, editingStep);
    previousSteps.splice(idx_p, 1, editingStep);

    this.setState({
      renderedSteps,
      previousSteps,
      editingStep: null,
      disabled: true,
      inputValue: '',
    });
  };

  editUserImage = () => {
    const { handleImageUpload } = this.props;
    const {
      editingStep,
      inputValue,
      renderedSteps,
      previousSteps,
      inputImage,
    } = this.state;

    const submitImage = (imgUrl) => {
      editingStep.message = inputValue;
      editingStep.image = true;
      editingStep.value = imgUrl;

      const idx_r = renderedSteps.findIndex(
        (step) => step.id === editingStep.id
      );
      const idx_p = previousSteps.findIndex(
        (step) => step.id === editingStep.id
      );

      renderedSteps.splice(idx_r, 1, editingStep);
      previousSteps.splice(idx_p, 1, editingStep);

      this.setState(
        {
          renderedSteps,
          previousSteps,
          disabled: true,
          inputValue: '',
          imageUploading: false,
          inputImage: undefined,
          editingStep: null,
        },
        () => {
          if (this.input) {
            this.input.blur();
          }
        }
      );
    };

    if (handleImageUpload) {
      this.setState({ imageUploading: true });
      handleImageUpload(inputImage, submitImage);
    }
  };

  checkInvalidInput = () => {
    const { enableMobileAutoFocus } = this.props;
    const { currentStep, inputValue } = this.state;
    const result = currentStep.validator(inputValue);
    const value = inputValue;

    if (typeof result !== 'boolean' || !result) {
      this.setState(
        {
          inputValue: result.toString(),
          inputInvalid: true,
          disabled: true,
        },
        () => {
          setTimeout(() => {
            this.setState(
              {
                inputValue: value,
                inputInvalid: false,
                disabled: false,
              },
              () => {
                if (enableMobileAutoFocus || !isMobile()) {
                  if (this.input) {
                    this.input.focus();
                  }
                }
              }
            );
          }, 2000);
        }
      );

      return true;
    }

    return false;
  };

  toggleChatBot = (opened) => {
    const { toggleFloating } = this.props;

    if (toggleFloating) {
      toggleFloating({ opened });
    } else {
      this.setState({ opened });
    }
  };

  editText = (step) => {
    this.setState({
      inputValue: step.message,
      disabled: false,
      editingStep: step,
    });
  };

  renderStep = (step, index) => {
    const { renderedSteps, imageUploading } = this.state;
    const {
      avatarStyle,
      bubbleStyle,
      bubbleOptionStyle,
      customStyle,
      hideBotAvatar,
      hideUserAvatar,
      speechSynthesis,
    } = this.props;
    const { options, component, asMessage, image } = step;
    const steps = this.generateRenderedStepsById();
    const previousStep = index > 0 ? renderedSteps[index - 1] : {};

    if (component && !asMessage) {
      return (
        <CustomStep
          key={index}
          speak={this.speak}
          step={step}
          steps={steps}
          style={customStyle}
          previousStep={previousStep}
          previousValue={previousStep.value}
          triggerNextStep={this.triggerNextStep}
        />
      );
    }

    if (options) {
      return (
        <OptionsStep
          key={index}
          step={step}
          previousValue={previousStep.value}
          triggerNextStep={this.triggerNextStep}
          bubbleOptionStyle={bubbleOptionStyle}
        />
      );
    }

    if (image) {
      return (
        <ImageStep
          key={index}
          step={step}
          imageUploading={imageUploading}
          triggerNextStep={this.triggerNextStep}
          editText={this.editText}
          isFirst={this.isFirstPosition(step)}
          isLast={this.isLastPosition(step)}
        />
      );
    }

    return (
      <TextStep
        key={index}
        step={step}
        steps={steps}
        speak={this.speak}
        previousStep={previousStep}
        previousValue={previousStep.value}
        triggerNextStep={this.triggerNextStep}
        avatarStyle={avatarStyle}
        bubbleStyle={bubbleStyle}
        hideBotAvatar={hideBotAvatar}
        hideUserAvatar={hideUserAvatar}
        speechSynthesis={speechSynthesis}
        isFirst={this.isFirstPosition(step)}
        isLast={this.isLastPosition(step)}
        editText={this.editText}
        index={index}
        renderedSteps={renderedSteps}
      />
    );
  };

  render() {
    const {
      currentStep,
      disabled,
      inputInvalid,
      inputValue,
      opened,
      renderedSteps,
      speaking,
      recognitionEnable,
      inputImage,
      inputImageBase64,
      detailsView,
    } = this.state;
    const {
      className,
      contentStyle,
      extraControl,
      controlStyle,
      floating,
      floatingIcon,
      floatingStyle,
      footerStyle,
      headerComponent,
      headerTitle,
      hideHeader,
      hideSubmitButton,
      inputStyle,
      placeholder,
      inputAttributes,
      recognitionPlaceholder,
      style,
      submitButtonStyle,
      width,
      // height,
    } = this.props;

    const header = <>
      {headerComponent && detailsView ? headerComponent : (
        <Header className="rsc-header">
          <HeaderTitle className="rsc-header-title">{headerTitle}</HeaderTitle>
          {/* {floating && (
            <HeaderIcon
              className="rsc-header-close-button"
              onClick={() => this.toggleChatBot(false)}>
              <CloseIcon />
            </HeaderIcon>
          )} */}
        </Header>
      )}
      {headerComponent && <ExpandCollapseIcon
        className="rsc-header-collapse-button"
        detailsView={detailsView}
        onClick={() => this.setState(prevState => ({ detailsView: !prevState.detailsView }))}>
        {detailsView ? <UpIcon /> : <DownIcon />}
      </ExpandCollapseIcon>}
    </>

    let customControl;
    if (extraControl !== undefined) {
      customControl = React.cloneElement(extraControl, {
        disabled,
        speaking,
        invalid: inputInvalid,
      });
    }

    const icon =
      (this.isInputValueEmpty() || speaking) && recognitionEnable ? (
        <MicIcon />
      ) : (
          <SubmitIcon />
        );

    const inputPlaceholder = speaking
      ? recognitionPlaceholder
      : currentStep.placeholder || placeholder;

    const inputAttributesOverride =
      currentStep.inputAttributes || inputAttributes;

    let extraHeight = (detailsView ? 210 : -25);
    if (!disabled) {
      extraHeight += (inputValue ? 100 : 75);
    }

    return (
      <div className={`rsc ${className}`}>
        {floating && (
          <FloatButton
            className="rsc-float-button"
            style={floatingStyle}
            opened={opened}
            onClick={() => this.toggleChatBot(true)}>
            {typeof floatingIcon === 'string' ? (
              <FloatingIcon src={floatingIcon} />
            ) : (
                floatingIcon
              )}
          </FloatButton>
        )}
        <ChatBotContainer
          className="rsc-container"
          floating={floating}
          floatingStyle={floatingStyle}
          opened={opened}
          style={style}
          width={width}>
          {!hideHeader && header}
          <Content
            className="rsc-content"
            ref={this.setContentRef}
            floating={floating}
            style={contentStyle}
            height={`100% - ${extraHeight}px`}
            hideInput={currentStep.hideInput}>
            {renderedSteps.map(this.renderStep)}
            {inputImage && (
              <div
                style={{
                  display: 'inline-flex',
                  bottom: inputValue ? 105 : 75,
                  padding: 10,
                  marginLeft: 20,
                  marginRight: 20,
                  position: 'absolute',
                  backgroundColor: 'white',
                  boxShadow: '0 1px 2px 0 rgba(0,0,0,0.15)',
                }}>
                <div style={{ marginRight: 10, alignSelf: 'center' }}>
                  Image to upload
                </div>
                <img
                  alt={'inputImg'}
                  src={inputImageBase64}
                  style={{
                    width: 50,
                    height: 50,
                    float: 'right',
                  }}
                />
              </div>
            )}
          </Content>
          {!disabled && (
            <Footer className="rsc-footer" style={footerStyle}>
              <div
                style={controlStyle}
                id="attachIcon"
                className="rsc-controls">
                {!currentStep.hideInput && (
                  <div>
                    <label htmlFor="my_file">
                      <AttachIcon disabled={disabled} />
                    </label>
                    <input
                      type="file"
                      id="my_file"
                      style={{ display: 'none' }}
                      ref={this.fileUpload}
                      accept="image/png, image/jpeg"
                      onChange={this.addImageToInput}
                      disabled={disabled}
                    />
                  </div>
                )}
              </div>
              {!currentStep.hideInput && (
                <Input
                  style={inputStyle}
                  ref={this.setInputRef}
                  className="rsc-input"
                  placeholder={inputInvalid ? '' : inputPlaceholder}
                  onKeyPress={this.handleKeyPress}
                  onChange={this.onValueChange}
                  value={inputValue}
                  floating={floating}
                  invalid={inputInvalid}
                  disabled={disabled}
                  hasButton={!hideSubmitButton}
                  maxlength="500"
                  {...inputAttributesOverride}
                />
              )}
              <div style={controlStyle} className="rsc-controls">
                {!currentStep.hideInput &&
                  !currentStep.hideExtraControl &&
                  customControl}
                {!currentStep.hideInput && !hideSubmitButton && (
                  <SubmitButton
                    className="rsc-submit-button"
                    style={submitButtonStyle}
                    onClick={this.handleSubmitButton}
                    invalid={inputInvalid}
                    disabled={disabled || inputValue === ''}
                    speaking={speaking}>
                    {icon}
                  </SubmitButton>
                )}
              </div>
              {inputValue && (
                <div style={{ padding: 5, color: 'red' }}>
                  {500 - inputValue.length} characters remaining
                </div>
              )}
            </Footer>
          )}
        </ChatBotContainer>
      </div>
    );
  }
}

ChatBot.propTypes = {
  avatarStyle: PropTypes.objectOf(PropTypes.any),
  botAvatar: PropTypes.string,
  botDelay: PropTypes.number,
  bubbleOptionStyle: PropTypes.objectOf(PropTypes.any),
  bubbleStyle: PropTypes.objectOf(PropTypes.any),
  cache: PropTypes.bool,
  cacheName: PropTypes.string,
  className: PropTypes.string,
  contentStyle: PropTypes.objectOf(PropTypes.any),
  customDelay: PropTypes.number,
  customStyle: PropTypes.objectOf(PropTypes.any),
  controlStyle: PropTypes.objectOf(PropTypes.any),
  enableMobileAutoFocus: PropTypes.bool,
  enableSmoothScroll: PropTypes.bool,
  extraControl: PropTypes.objectOf(PropTypes.element),
  floating: PropTypes.bool,
  floatingIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  floatingStyle: PropTypes.objectOf(PropTypes.any),
  footerStyle: PropTypes.objectOf(PropTypes.any),
  handleEnd: PropTypes.func,
  handleImageUpload: PropTypes.func,
  headerComponent: PropTypes.element,
  headerTitle: PropTypes.string,
  height: PropTypes.number,
  hideBotAvatar: PropTypes.bool,
  hideHeader: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
  hideUserAvatar: PropTypes.bool,
  inputAttributes: PropTypes.objectOf(PropTypes.any),
  inputStyle: PropTypes.objectOf(PropTypes.any),
  opened: PropTypes.bool,
  toggleFloating: PropTypes.func,
  placeholder: PropTypes.string,
  recognitionEnable: PropTypes.bool,
  recognitionLang: PropTypes.string,
  recognitionPlaceholder: PropTypes.string,
  speechSynthesis: PropTypes.shape({
    enable: PropTypes.bool,
    lang: PropTypes.string,
    voice:
      typeof window !== 'undefined'
        ? PropTypes.instanceOf(window.SpeechSynthesisVoice)
        : PropTypes.any,
  }),
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  submitButtonStyle: PropTypes.objectOf(PropTypes.any),
  userAvatar: PropTypes.string,
  userDelay: PropTypes.number,
  width: PropTypes.string,
};

ChatBot.defaultProps = {
  avatarStyle: {},
  botDelay: 1000,
  bubbleOptionStyle: {},
  bubbleStyle: {},
  cache: false,
  cacheName: 'rsc_cache',
  className: '',
  contentStyle: {},
  customStyle: {},
  controlStyle: { position: 'absolute', right: '0', top: '0' },
  customDelay: 1000,
  enableMobileAutoFocus: false,
  enableSmoothScroll: false,
  extraControl: undefined,
  floating: false,
  floatingIcon: <ChatIcon />,
  floatingStyle: {},
  footerStyle: {},
  handleEnd: undefined,
  handleImageUpload: undefined,
  headerComponent: undefined,
  headerTitle: 'Chat',
  height: 520,
  hideBotAvatar: false,
  hideHeader: false,
  hideSubmitButton: false,
  hideUserAvatar: false,
  inputStyle: {},
  opened: undefined,
  placeholder: 'Type the message ...',
  inputAttributes: {},
  recognitionEnable: false,
  recognitionLang: 'en',
  recognitionPlaceholder: 'Listening ...',
  speechSynthesis: {
    enable: false,
    lang: 'en',
    voice: null,
  },
  style: {},
  submitButtonStyle: {},
  toggleFloating: undefined,
  userDelay: 1000,
  width: '350px',
  botAvatar:
    "data:image/svg+xml,%3csvg version='1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath d='M303 70a47 47 0 1 0-70 40v84h46v-84c14-8 24-23 24-40z' fill='%2393c7ef'/%3e%3cpath d='M256 23v171h23v-84a47 47 0 0 0-23-87z' fill='%235a8bb0'/%3e%3cpath fill='%2393c7ef' d='M0 240h248v124H0z'/%3e%3cpath fill='%235a8bb0' d='M264 240h248v124H264z'/%3e%3cpath fill='%2393c7ef' d='M186 365h140v124H186z'/%3e%3cpath fill='%235a8bb0' d='M256 365h70v124h-70z'/%3e%3cpath fill='%23cce9f9' d='M47 163h419v279H47z'/%3e%3cpath fill='%2393c7ef' d='M256 163h209v279H256z'/%3e%3cpath d='M194 272a31 31 0 0 1-62 0c0-18 14-32 31-32s31 14 31 32z' fill='%233c5d76'/%3e%3cpath d='M380 272a31 31 0 0 1-62 0c0-18 14-32 31-32s31 14 31 32z' fill='%231e2e3b'/%3e%3cpath d='M186 349a70 70 0 1 0 140 0H186z' fill='%233c5d76'/%3e%3cpath d='M256 349v70c39 0 70-31 70-70h-70z' fill='%231e2e3b'/%3e%3c/svg%3e",
  userAvatar:
    "data:image/svg+xml,%3csvg viewBox='-208.5 21 100 100' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ccircle cx='-158.5' cy='71' fill='%23F5EEE5' r='50'/%3e%3cdefs%3e%3ccircle cx='-158.5' cy='71' id='a' r='50'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse overflow='visible' xlink:href='%23a'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' fill='%23E6C19C'/%3e%3cg clip-path='url(%23b)'%3e%3cdefs%3e%3cpath d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' id='c'/%3e%3c/defs%3e%3cclipPath id='d'%3e%3cuse overflow='visible' xlink:href='%23c'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23d)' d='M-158.5 100.1c12.7 0 23-18.6 23-34.4 0-16.2-10.3-24.7-23-24.7s-23 8.5-23 24.7c0 15.8 10.3 34.4 23 34.4z' fill='%23D4B08C'/%3e%3c/g%3e%3cpath d='M-158.5 96c12.7 0 23-16.3 23-31 0-15.1-10.3-23-23-23s-23 7.9-23 23c0 14.7 10.3 31 23 31z' fill='%23F2CEA5'/%3e%3c/svg%3e",
};

export default ChatBot;
