const commonFlow = (data, options, responses, userInputs, followUps) => {
  data.knowledgeBase.map((k, i) => {
  	if (k.isDefault) {
  	  return null;
  	}

    options.push({
      value: `o_${i}`,
      label: k.option,
      trigger: `r_${i}`
    });

    if (k.needResponse) {
      if (k.responseType === 'Text') {
        responses.push({
          id: `r_${i}`,
          message: k.response,
          trigger: `u_${i}`
        });

        userInputs.push({
          id: `u_${i}`,
          user: true,
          trigger: `f_${i}`
        });

        followUps.push({
          id: `f_${i}`,
          message: k.followUpYes,
          trigger: 'penultimate'
        });
      } else if (k.responseType === 'Multiple choices') {
        responses.push({
          id: `r_${i}`,
          message: k.response,
          trigger: `options_${i}_mc`
        });

        responses.push({
          id: `options_${i}_mc`,
          options: [
            { value: 1, label: 'Yes', trigger: `f_${i}_yes` },
            { value: 2, label: 'No', trigger: `f_${i}_no` }
          ],
        });

        followUps.push({
          id: `f_${i}_yes`,
          message: k.followUpYes,
          trigger: 'penultimate'
        });

        followUps.push({
          id: `f_${i}_no`,
          message: k.followUpNo,
          trigger: 'penultimate'
        });
      }
    } else {
      responses.push({
        id: `r_${i}`,
        message: k.response,
        trigger: `penultimate`
      });
    }

    return null;
  });

  options.push({
    value: `o_others`,
    label: 'Others',
    trigger: `r_others`
  });

  responses.push({
    id: `r_others`,
    message: `Please tell me what you need, your name, and contact phone number. I will tranfer your messages to my manager.`,
    trigger: `u_others`
  });

  userInputs.push({
    id: `u_others`,
    user: true,
    trigger: `penultimate`
  });

  return {
  	options, 
  	responses, 
  	userInputs, 
  	followUps
  }
}

export {
 commonFlow
}
