import { commonFlow } from './common';

export default function getShopping(data) {
  let options = [{
    value: `o_order`,
    label: "I would like to order some products",
    trigger: `f_order_1`
  }];

  let responses = [
  ];

  let userInputs = [
    {
      id: `u_order_1`,
      user: true,
      trigger: `f_order_2`
    },
    {
      id: 'u_order_2',
      user: true,
      trigger: 'f_order_3'
    }
  ];

  let followUps = [
    {
      id: `f_order_1`,
      message: `I am happy to help. What do you want? Please specifiy the brand, name of the product, quantity of each product you want, etc. The more information the better`,
      trigger: `u_order_1`
    },
    {
      id: `f_order_2`,
      message: `Please tell me your name, phone number, and email. We will contact you once the order is ready.`,
      trigger: `u_order_2`
    },
    {
      id: 'f_order_3',
      message: data.botResponse[0],
      trigger: `options_order_3`
    },
    {
      id: `options_order_3`,
      options: [
        { value: 1, label: 'Confirm', trigger: `f_order_4` },
        { value: 2, label: 'No, I want to change my order', trigger: 'u_order_1' },
        { value: 3, label: 'Cancel my order and end the chat now', trigger: 'departing_thanks'}
      ],
    },
    {
      id: `f_order_4`,
      message: data.botResponse[1],
      trigger: 'penultimate'
    }
  ];

  // Arrays will mutate
  commonFlow(data, options, responses, userInputs, followUps);

  return [
    {
      id: 'start',
      message: data.greetings && data.greetings !== "" ? data.greetings : "Hello! How can I help?",
      trigger: 'options',
    },
    {
      id: 'options',
      options: options
    },
    ...responses,
    ...userInputs,
    ...followUps,
    {
      id: 'penultimate',
      message: "Can I help you with anything else?",
      trigger: 'options_2'
    },
    {
      id: 'options_2',
      options: [
        { value: 1, label: 'Yes, I would like to see the options.', trigger: 'options' },
        { value: 2, label: 'No, I would like to end the chat.', trigger: 'departing_thanks' },
        { value: 3, label: 'Start over.', trigger: () => window.location.reload() }
      ],
    },
    {
      id: 'departing_thanks',
      message: data.botResponse[2],
      trigger: 'bye'
    },
    {
      id: 'bye',
      message: 'Bye!',
      end: true
    }
  ];
}
